<template>
  <div @click="toogleSwitch">
    <!--Body-->
    <div
      class="require-keywords w-11 py-0.5 h-5 rounded-full cursor-pointer"
      :class="{
        'bg-bb-brand-purple': status === SWITCH_STATUS.ENABLED,
        'bg-bb-disabled-buttons': status === SWITCH_STATUS.DISABLED,
        'bg-neutral-50': !toggleable,
      }"
    >
      <!--Thumb-->
      <div
        class="h-4 w-4 rounded-full mx-0.5 transition transform duration-300"
        :class="{
          'bg-white translate-x-6': status === SWITCH_STATUS.ENABLED,
          'bg-white translate-x-0': status === SWITCH_STATUS.DISABLED,
          'bg-bb-disabled-buttons translate-x-6': status === SWITCH_STATUS.ENABLED_BUT_INACTIVE,
          'bg-bb-disabled-buttons translate-x-0': status === SWITCH_STATUS.DISABLED_AND_INACTIVE,
        }"
      ></div>
    </div>
  </div>
</template>

<script>
import { SWITCH_STATUS } from '@/utils/enums'

export default {
  name: 'SliderSwitch',
  props: {
    status: {
      type: String,
      default: SWITCH_STATUS.DISABLED,
      validator: item => Object.keys(SWITCH_STATUS).includes(item),
    },
    type: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      SWITCH_STATUS,
    }
  },
  computed: {
    toggleable() {
      return [SWITCH_STATUS.ENABLED, SWITCH_STATUS.DISABLED].includes(this.status)
    },
  },
  methods: {
    toogleSwitch() {
      this.$emit(
        'update-status',
        this.id,
        this.type,
        this.status === SWITCH_STATUS.ENABLED ? SWITCH_STATUS.DISABLED : SWITCH_STATUS.ENABLED,
      )
    },
  },
}
</script>

<style scoped lang="scss"></style>
