<template>
  <div class="text-bb-text-default pt-6">
    <div class="space-y-2 px-6">
      <p class="h3">Business Description Troubleshoot</p>
      <p>
        Accurate “Business description” is crucial for our AI to generate high-quality keyword suggestions. This tool
        helps you refine and test your description to see if it accurately conveys the intent and relevance for your
        search terms.
      </p>
    </div>

    <div class="max-h-140 w-full overflow-y-auto base-scrollbar my-6">
      <div class="w-full px-6">
        <text-area
          v-model="troubleshootForm.businessDescription"
          input-name="business-description"
          input-id="business-description"
          label="Business Description"
          sub-label="Edit or update your business description"
          :min-length="inputLengths.businessDescription.min"
          :max-length="inputLengths.businessDescription.max"
          :error-message="businessDescriptionErrorMessage"
          @focusout="$v.troubleshootForm.businessDescription.$touch"
        />
        <text-area
          v-model="troubleshootForm.extraInfo"
          input-name="help-the-ai"
          input-id="help-the-ai"
          sub-label="Our models still have a lot to learn! Help our AI with extra information it should keep in mind when working with your account"
          :min-length="inputLengths.extraInfo.min"
          :max-length="inputLengths.extraInfo.max"
          :error-message="extraInfoErrorMessage"
          :rows="5"
          @focusout="$v.troubleshootForm.extraInfo.$touch"
        >
          <template #label>
            <div class="flex items-center pb-2 gap-2">
              <ic-chat
                class="text-bb-brand-purple"
                size="20"
              />
              <p class="font-medium">Extra info: Help the AI</p>
            </div>
          </template>
        </text-area>
        <search-input
          ref="offering"
          v-model="troubleshootForm.selectedOffering"
          v-scroll-to-me
          placeholder="Select Offering"
          input-id="offering"
          label="Link Offering"
          sub-label="Associate your offering to the Business description"
          :options="offeringOptions"
          @select-item="selectOffering"
        >
          <template #label-icon>
            <ic-link
              size="24"
              class="text-bb-disabled-buttons"
            />
          </template>
        </search-input>

        <div class="shadow-bb-shadow border divide-y rounded-lg mt-6 overflow-hidden">
          <div class="grid grid-cols-12 px-6 py-5">
            <div class="col-span-6">
              <h5 class="h5 text-bb-text-default">Expressions</h5>
            </div>

            <div class="col-span-3 text-center my-auto">
              <p class="text-sm font-medium text-bb-text-default">Relevance</p>
            </div>
            <div class="col-span-3 my-auto">
              <p class="text-sm font-medium text-bb-text-default text-center">Intent</p>
            </div>
          </div>
          <div>
            <div
              v-for="(item, index) in topKeywordsForm"
              :key="index"
              class="hover:bg-bb-background-purple-0 hover:border-bb-brand-purple border-white border-l-4 duration-300 ease-linear"
            >
              <div class="grid grid-cols-12 px-4 h-16 items-center pb-2">
                <div class="col-span-6">
                  <text-input
                    v-model="item.keyword"
                    class="w-full"
                    :input-name="`expression-${index + 1}`"
                    :input-id="`expression-${index}`"
                  />
                </div>

                <div class="col-span-3 my-auto mx-auto">
                  <div class="capitalize">{{ topKeywords[index].relevance }}</div>
                </div>
                <div class="col-span-3 my-auto text-center">
                  <div
                    class="h-8 flex items-center justify-center w-25 text-sm font-medium rounded-md"
                    :class="`${topKeywords[index].intent}`"
                  >
                    <p class="capitalize">{{ topKeywords[index].intent }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="text-xs text-bb-text-secondary mt-2">Expressions are for testing purposes and will not be saved.</p>
        <div class="pt-6">
          <merge-button-round
            button-type="primary"
            class="w-full"
            @click="testOnExpressions"
          >
            Test the expressions
          </merge-button-round>
        </div>
      </div>
    </div>

    <div class="flex justify-between items-center px-6">
      <merge-button-round
        button-type="secondary"
        @click="$emit('close')"
      >
        <p>Cancel</p>
      </merge-button-round>
      <merge-button-round
        button-type="primary"
        @click="save"
      >
        <p>Save</p>
      </merge-button-round>
    </div>

    <div
      v-if="isModalLoading"
      class="fixed inset-0 bg-white rounded-lg z-50 bg-opacity-75 transition-opacity"
    >
      <div class="h-full flex items-center justify-center">
        <brightbid-loader size="120" />
      </div>
    </div>
  </div>
</template>

<script>
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import TextArea from '@/components/input/brightbid/TextArea.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import IcChat from '@/components/icon/brightbid/ic-chat.vue'
import IcLink from '@/components/icon/brightbid/ic-link.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'

export default {
  name: 'TroubleshootBusinessDescription',
  components: { TextArea, IcChat, SearchInput, IcLink, TextInput, BrightbidLoader },
  props: {
    offerings: {
      type: Array,
      default: () => [],
    },
    topKeywords: {
      type: Array,
      default: () => [],
    },
    extraInfo: {
      type: String,
      default: '',
    },
    businessDescription: {
      type: String,
      default: '',
    },
    isModalLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      troubleshootForm: {
        businessDescription: '',
        extraInfo: '',
        selectedOffering: null,
      },
      inputLengths: {
        businessDescription: {
          min: 25,
          max: 150,
        },
        extraInfo: {
          max: 300,
        },
      },
      topKeywordsForm: [],
    }
  },
  computed: {
    offeringOptions() {
      return this.offerings.map(offering => ({
        value: offering.search_customer_product_id,
        label: offering.name,
      }))
    },
    businessDescriptionErrorMessage() {
      const lengths = this.inputLengths.businessDescription
      if (!this.$v.troubleshootForm.businessDescription.$dirty) return null
      if (!this.$v.troubleshootForm.businessDescription.required) return 'Description is required'
      if (!this.$v.troubleshootForm.businessDescription.minLength)
        return `Please use at least ${lengths.min} characters.`
      if (!this.$v.troubleshootForm.businessDescription.maxLength)
        return `Please use at most ${lengths.max} characters.`
      return null
    },
    extraInfoErrorMessage() {
      const lengths = this.inputLengths.extraInfo
      if (!this.$v.troubleshootForm.extraInfo.$dirty) return null
      if (!this.$v.troubleshootForm.extraInfo.maxLength) return `Please use at most ${lengths.max} characters.`
      return null
    },
  },
  mounted() {
    this.troubleshootForm = {
      businessDescription: this.businessDescription,
      extraInfo: this.extraInfo,
    }
    this.topKeywordsForm = structuredClone(this.topKeywords)
  },
  validations() {
    return {
      troubleshootForm: {
        businessDescription: {
          required,
          minLength: minLength(this.inputLengths.businessDescription.min),
          maxLength: maxLength(this.inputLengths.businessDescription.max),
        },
        extraInfo: {
          maxLength: maxLength(this.inputLengths.extraInfo.max),
        },
      },
    }
  },
  methods: {
    async testOnExpressions() {
      this.$v.troubleshootForm.$touch()
      if (this.$v.troubleshootForm.$invalid) return

      const payload = {
        business_description: this.troubleshootForm.businessDescription,
        extra_info: this.troubleshootForm.extraInfo,
        expressions: this.topKeywordsForm.filter(item => item.keyword.trim() !== '').map(item => item.keyword),
      }
      if (this.troubleshootForm.selectedOffering?.value) {
        payload.product_uuid = this.troubleshootForm.selectedOffering.value
      }

      this.$emit('test-on-expressions', payload, this.topKeywordsForm)
    },
    selectOffering(offering) {
      if (this.troubleshootForm.selectedOffering && offering.value === this.troubleshootForm.selectedOffering.value) {
        this.troubleshootForm.selectedOffering = { label: '', value: '' }
        this.$refs.offering.searchInput = null
        this.$refs.offering.selectedItem = null
        return
      }
      this.troubleshootForm.selectedOffering = offering
    },
    save() {
      this.$v.troubleshootForm.$touch()
      if (this.$v.troubleshootForm.$invalid) return

      const payload = {
        description: this.troubleshootForm.businessDescription,
        extra_info: this.troubleshootForm.extraInfo,
      }

      this.$emit('update-business-description-and-extra-info', payload)
    },
  },
}
</script>

<style scoped lang="scss">
.transactional {
  background: #beddff;
}
.informational {
  background: #ffaacc;
}
.commercial {
  background: #b3ffe5;
}
.navigational {
  background: #ffe9ab;
}
</style>
